import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DefaultFontStack } from "../typography/typography";

const CaptionTypography = styled.figcaption`
  ${DefaultFontStack}
  font-size: 15px;
  color: ${(props) => props.theme.colors.mediumEmphasis};
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 0px;
`;

const Root = styled.figure`
  max-width: ${({ $width }) => $width}px;
  margin: 0px;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  transform: ${({ $show }) => ($show ? "scale(1)" : "scale(0)")};
  transition: ${({ $animate }) =>
    $animate ? "transform 200ms linear" : "unset"};
`;

const StyledGatsbyImageContainer = styled.div`
  transform: ${({ $show }) => ($show ? "scale(1)" : "scale(0)")};
  transition: ${({ $animate }) =>
    $animate ? "transform 200ms linear" : "unset"};
`;

const Image = ({
  className,
  src,
  alt,
  renderCaption,
  animate,
  objectFit = "contain",
}) => {
  const imageRef = useRef(null);
  const [inViewport, setInViewport] = useState(false);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px", // No margin
      threshold: 0.5, // Trigger when 50% of the element is in the viewport
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInViewport(true);
          observer.disconnect(); // Stop observing once it's in the viewport
        }
      });
    }, options);

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    // Cleanup when component unmounts
    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  if (renderCaption) {
    return (
      <Root ref={imageRef} className={className} $width={getImage(src).width}>
        <StyledGatsbyImage
          $animate={animate}
          $show={inViewport}
          objectFit={objectFit}
          image={getImage(src)}
          alt={alt}
        />
        <CaptionTypography>{renderCaption()}</CaptionTypography>
      </Root>
    );
  } else {
    return (
      <StyledGatsbyImageContainer
        $animate={animate}
        ref={imageRef}
        $show={inViewport}
      >
        <StyledGatsbyImage
          $show={true}
          className={className}
          objectFit="contain"
          image={getImage(src)}
          alt={alt}
        />
      </StyledGatsbyImageContainer>
    );
  }
};

Image.propTypes = {
  src: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

export default styled(Image)``;
