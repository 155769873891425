import React from "react";
import styled from "styled-components";
import Link from "../../link/link";

const StyledLink = styled(Link)(({ theme }) => ({
  userSelect: "none",
  backgroundColor: theme.colors.awesome,
  borderRadius: 17,
  boxShadow: "0px 0px 4px rgb(0, 0, 0, 0.32)",
  color: theme.colors.day,
  display: "block",
  fontFamily: "Arial",
  fontWeight: 500,
  fontSize: 17,
  height: "fit-content",
  letterSpacing: 1.5,
  lineHeight: "30px",
  padding: "2px 12px",
  textAlign: "center",
  width: "fit-content",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgb(11, 78, 134)",
  },
}));

const StyledButton = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  userSelect: "none",
  backgroundColor: theme.colors.awesome,
  borderRadius: 17,
  boxShadow: "0px 0px 4px rgb(0, 0, 0, 0.32)",
  color: theme.colors.day,
  display: "block",
  fontFamily: "Arial",
  fontWeight: 500,
  fontSize: 17,
  height: "fit-content",
  letterSpacing: 1.5,
  lineHeight: "30px",
  padding: "2px 12px",
  textAlign: "center",
  width: "fit-content",
  "&:hover": {
    backgroundColor: "rgb(11, 78, 134)",
  },
}));

const CallToActionButton = ({ className, children, url, onClick }) => {
  const isInternalLink = (url) => {
    return url?.[0] === "/";
  };

  const isFile = (url) => {
    const fileType = url ? url.split(".")[1] : "";
    return fileType === "pdf" || fileType === "docx" || fileType === "zip";
  };

  return onClick === undefined ? (
    <StyledLink
      className={className}
      href={url}
      url={url}
      rel={!isFile(url) && isInternalLink(url) ? null : "opener"}
      target={!isFile(url) && isInternalLink(url) ? null : "_blank"}
    >
      {children}
    </StyledLink>
  ) : (
    <StyledButton className={className} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default styled(CallToActionButton)``;
