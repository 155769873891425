import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: ${(props) => (props.$fullWidth ? 0 : 15)}px;
  margin-right: ${(props) => (props.$fullWidth ? 0 : 15)}px;
  background-color: ${(props) => props.backgroundColor};
`;

const MaxWidth = styled.div`
  width: 100%;
  max-width: ${(props) => props.$maxWidth}px;
`;

const Section = ({
  className,
  children,
  maxWidth = 916,
  fullWidth = false,
  backgroundColor,
}) => {
  return (
    <div className={className} style={{ backgroundColor, width: "100%" }}>
      <Container $fullWidth={fullWidth}>
        {fullWidth ? (
          children
        ) : (
          <MaxWidth $maxWidth={maxWidth}>{children}</MaxWidth>
        )}
      </Container>
    </div>
  );
};

export default styled(Section)``;
