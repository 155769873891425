import styled from "styled-components";

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $vertically }) => ($vertically ? "center" : "start")};
  align-items: ${({ $horizontally }) => ($horizontally ? "center" : "start")};
`;

export default Center;
