import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Root = styled.div`
  padding-top: ${({ $top }) => `${$top}px`};
  padding-bottom: ${({ $bottom }) => `${$bottom}px`};
  padding-left: ${({ $left }) => `${$left}px`};
  padding-right: ${({ $right }) => `${$right}px`};
`;

function Padding(props) {
  const { top = 0, bottom = 0, left = 0, right = 0, children } = props;

  return (
    <Root $top={top} $bottom={bottom} $left={left} $right={right}>
      {children}
    </Root>
  );
}

Padding.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
};

export default Padding;
