import styled from "styled-components";
import { Body1CSS } from "../../components/typography/typography";

const Formula = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Operand = styled.div`
  ${Body1CSS}
  height: 150px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.day};
  margin: 0px 10px;
  font-size: 25px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-align: center;
  padding: 10px;
`;

const Operator = styled.div`
  ${Body1CSS}
  margin: 0px 10px;
  font-size: 60px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.emperor};
  text-align: center;
`;

export { Formula, Operand, Operator };
