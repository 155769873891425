import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  DecorativeFontStack,
  ImageCaptionText,
} from "../typography/typography";

const QuoteCharacterTypography = styled.span`
  ${DecorativeFontStack}
  font-family: serif;
  font-size: 80px;
  color: ${(props) => props.theme.colors.cruise};
  line-height: 0px;
`;

const PullQuoteTypography = styled.span`
  ${DecorativeFontStack}
  font-size: 25px;
  letter-spacing: 0.5px;
  line-height: 38px;
  color: ${(props) => props.theme.colors.mediumEmphasis};
`;

const NoWrapRoot = styled.div`
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
`;

const NoWrapBorderContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 15px;
`;

const NoWrapBorder = styled.div`
  position: relative;
  top: -50px;
  flex-grow: 1;
  border-left: 5px dotted ${(props) => props.theme.colors.cruise};
`;

const Attribution = styled(ImageCaptionText)`
  text-align: left;
  margin-top: 20px;
`;

const NoWrapPullQuote = ({ className, style, attribution, children }) => {
  return (
    <NoWrapRoot className={className} style={style}>
      <div style={{ marginTop: "35px", marginRight: "10px" }}>
        <QuoteCharacterTypography>&#8220;</QuoteCharacterTypography>
        <NoWrapBorderContainer>
          <NoWrapBorder />
        </NoWrapBorderContainer>
      </div>
      <PullQuoteTypography>
        {children}
        <Attribution>{attribution}</Attribution>
      </PullQuoteTypography>
    </NoWrapRoot>
  );
};

NoWrapPullQuote.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  attribution: PropTypes.string,
  style: PropTypes.object,
};

const WrapRoot = styled.span`
  display: block;
  ${DecorativeFontStack}
  font-size: 29px;
  letter-spacing: 0.5px;
  line-height: 38px;
  color: ${(props) => props.theme.colors.mediumEmphasis};
  border-top: 5px dotted ${(props) => props.theme.colors.cruise};
  border-bottom: 5px dotted ${(props) => props.theme.colors.cruise};
  position: relative;

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    display: inline;
    margin-right: 15px;
    float: left;
    max-width: 400px;
  }

  ${QuoteCharacterTypography} {
    position: relative;
    top: 32px;
    margin-right: 3px;
  }
`;

const WrapPullQuote = ({ className, style, attribution, children }) => {
  return (
    <WrapRoot className={className} attribution={attribution} style={style}>
      <QuoteCharacterTypography>&#8220;</QuoteCharacterTypography>
      {children}
      <Attribution>{attribution}</Attribution>
    </WrapRoot>
  );
};

WrapPullQuote.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

const PullQuote = ({
  className,
  style,
  attribution,
  children,
  wrap = false,
}) => {
  if (wrap) {
    return (
      <WrapPullQuote
        className={className}
        attribution={attribution}
        style={style}
      >
        {children}
      </WrapPullQuote>
    );
  } else {
    return (
      <NoWrapPullQuote
        className={className}
        attribution={attribution}
        style={style}
      >
        {children}
      </NoWrapPullQuote>
    );
  }
};

PullQuote.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  attribution: PropTypes.string,
  style: PropTypes.object,
  wrap: PropTypes.bool,
};

export default styled(PullQuote)``;
