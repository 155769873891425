import React from "react";
import ThemeProvider from "./theme/theme";
import { UserTrackingProvider } from "./user-tracking/user-tracking-provider";

const Providers = ({ children, location }) => {
  return (
    <UserTrackingProvider location={location}>
      <ThemeProvider>{children}</ThemeProvider>
    </UserTrackingProvider>
  );
};

export default Providers;
