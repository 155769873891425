import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Body1CSS, Heading1CSS } from "../typography/typography";

const ImageText = styled.span`
  ${Heading1CSS}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  color: ${(props) => props.theme.colors.awesome};
`;

const Text = styled.span`
  ${Body1CSS}
`;

const BigIdeaImageContainer = styled.div`
  position: relative;
  min-width: 150px;
  max-width: 150px;
  min-height: 125px;
  max-height: 125px;

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    margin-right: 50px;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
`;

const BigIdeaImage = () => {
  const imageData = useStaticQuery(graphql`
    query {
      data: file(relativePath: { eq: "lightbulb.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FIXED, height: 125)
        }
      }
    }
  `);

  return (
    <BigIdeaImageContainer>
      <ImageText>THE BIG IDEA</ImageText>
      <StyledGatsbyImage image={getImage(imageData.data)} alt="THE BIG IDEA" />
    </BigIdeaImageContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    flex-direction: row;
  }
`;

const BigIdea = ({ className, children }) => {
  return (
    <Container className={className}>
      <BigIdeaImage />
      <Text style={{ flexShrink: 1 }}>{children}</Text>
    </Container>
  );
};

export default styled(BigIdea)``;
