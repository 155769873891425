import React, { useRef, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import Link from "../link/link";
import { DefaultFontStack } from "../typography/typography";

const Root = styled.div``;
const QuickLink = styled(Link)`
  ${DefaultFontStack}
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
`;

const Bullet = styled.span`
  margin-left: 10px;
  margin-right: 10px;
`;

function QuickLinks({ className, tag = "h2" }) {
  const [links, setLinks] = useState([]);
  const [rootWidth, setRootWidth] = useState(0);
  const rootRef = useRef();

  useLayoutEffect(() => {
    (async () => {
      const results = Array.from(document.body.querySelectorAll(tag)).map(
        (element) => ({
          title: element.textContent,
          to: `#${element.id}`,
          width: 9 * element.textContent.length,
        }),
      );
      setLinks(results);
    })();
    const resizeObserver = new ResizeObserver(([root]) => {
      setRootWidth(root.contentRect.width);
    });
    resizeObserver.observe(rootRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  const results = links.reduce(
    (acc, link) => {
      if (acc.currentRowWidth + link.width + 5.6 + 10 + 10 < rootWidth) {
        acc.rows[acc.currentRowId].push(link);
        acc.currentRowWidth += link.width + 5.6 + 10 + 10;
      } else {
        acc.rows.push([link]);
        acc.currentRowId += 1;
        acc.currentRowWidth = link.width + 5.6 + 10 + 10;
      }

      return acc;
    },
    {
      rows: [[]],
      currentRowId: 0,
      currentRowWidth: 0,
    },
  );

  const elements = results.rows.map((row, rowId) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={rowId}>
      {row.map((link, index, arr) => (
        <div key={link.title} style={{ display: "inline" }}>
          <QuickLink url={link.to}>{link.title}</QuickLink>
          {index !== arr.length - 1 ? <Bullet>&#8226;</Bullet> : null}
        </div>
      ))}
    </div>
  ));

  return (
    <Root className={className} ref={rootRef}>
      {elements}
    </Root>
  );
}

QuickLinks.propTypes = {};

export default styled(QuickLinks)``;
