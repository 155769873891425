const React = require("react");
const Providers = require("./providers/providers").default;

exports.wrapPageElement = ({ element, props }, themeOptions) => {
  return (
    <Providers {...props} marketing={themeOptions.marketing}>
      {element}
    </Providers>
  );
};
