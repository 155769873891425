import React from "react";
import styled from "styled-components";
import { DefaultFontStack } from "../typography/typography";

const TableText = styled.span`
  ${DefaultFontStack}
  font-size: 17px;
  color: ${(props) => props.theme.colors.highEmphasis};
  line-height: 25px;
  letter-spacing: 0.5px;
  font-size: 17px;
`;

const TABLE_DIRECTION_LOOKUP = {
  L: "left",
  R: "right",
  C: "center",
};

const TABLE_VERTICAL_DIRECTION_LOOKUP = {
  T: "top",
  B: "bottom",
  M: "middle",
};

const StyledTable = styled.table`
  table-layout: fixed;
  border-collapse: separate;
  min-width: 700px;
  th {
    &:first-child {
      background-color: ${({ theme }) => theme.colors.awesome};
    }
    background-color: ${({ theme }) => theme.colors.emperor};
  }

  th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  tr {
    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  ${({ $align }) => {
    return $align
      .toUpperCase()
      .split(" ")
      .map((direction, index) => {
        return [
          `th { &:nth-child(${index + 1}) { text-align: ${
            TABLE_DIRECTION_LOOKUP[direction]
          }; } }`,
          `td { &:nth-child(${index + 1}) { text-align: ${
            TABLE_DIRECTION_LOOKUP[direction]
          }; } }`,
        ].join("");
      })
      .join("");
  }}

  ${({ $verticalAlign }) => {
    return $verticalAlign
      .toUpperCase()
      .split(" ")
      .map((direction, index) => {
        return [
          `th { &:nth-child(${index + 1}) { vertical-align: ${
            TABLE_VERTICAL_DIRECTION_LOOKUP[direction]
          }; } }`,
          `td { &:nth-child(${index + 1}) { vertical-align: ${
            TABLE_VERTICAL_DIRECTION_LOOKUP[direction]
          }; } }`,
        ].join("");
      })
      .join("");
  }}

  ${({ $cellWidths }) => {
    return $cellWidths
      .split(" ")
      .map((width, index) => {
        return `
        td:nth-child(${index + 1}) {
          width: ${width}%;
        }
      `;
      })
      .join("");
  }}

  margin: 0px;
  border-spacing: 6px;
`;

StyledTable.defaultProps = {
  $align: "",
  $cellWidths: "",
  $verticalAlign: "T T T T T T T T T T",
};

const TableHeader = styled.thead`
  color: #fff;
`;

const TableFooter = styled.tfoot``;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.ice};
  }
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.cloud};
  }
`;

const TableCell = styled(TableText).attrs({ as: "td" })`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "unset"};
  color: ${({ textColor }) => (textColor ? textColor : "unset")};
  ${({ verticalAlign }) =>
    verticalAlign ? `vertical-align: ${verticalAlign} !important;` : null}
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  ul {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-right: 0px;
    padding: 0px;
  }
  ol {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-right: 0px;
    padding: 0px;
  }
`;

const TableHeaderCell = styled(TableText).attrs({ as: "th" })`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: white;
`;

const Table = styled(
  ({ className, align, verticalAlign, cellWidths, children }) => {
    return (
      <div
        className={className}
        style={{ overflowX: "auto", maxWidth: "100%" }}
      >
        <StyledTable
          $align={align}
          $verticalAlign={verticalAlign}
          $cellWidths={cellWidths}
        >
          {children}
        </StyledTable>
      </div>
    );
  },
)``;

Table.defaultProps = {
  align: "",
  cellWidths: "",
};

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TableHeaderCell,
};
