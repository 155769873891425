import React from "react";

import { ThemeProvider as StyledThemeProvider } from "styled-components";
import GlobalStyleSheet from "../../styles/global";

const colors = {
  day: "#FFFFFF",
  ice: "#F0F5FA",
  cruise: "#A5C8E4",
  awesome: "#1070C0",
  lowEmphasis: "rgba(0, 0, 0, 0.34)",
  highEmphasis: "rgba(0, 0, 0, 0.82)",
  mediumEmphasis: "rgba(0, 0, 0, 0.58)",
  emperor: "#464646",
  manatee: "#6A6A6A",
  silver: "#DDDDDD",
  cloud: "#F2F2F2",
  deep: "#075391",
  deeper: "#203A52",
  sprout: "#228b22",
  purple: "#8b8ac9",
  orange: "#ff5a31",
};

const theme = {
  colors,
  breakpoints: {
    up: (value) => {
      if (value === "tablet") {
        return `@media(min-width: 720px)`;
      } else if (value === "desktop") {
        return `@media(min-width: 1024px)`;
      } else {
        return `@media(min-width: ${value}px)`;
      }
    },
  },
};

const ThemeProvider = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyleSheet />
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </React.Fragment>
  );
};

export default ThemeProvider;

export { colors };
