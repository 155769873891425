import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled, { css, withTheme } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Body1CSS } from "../typography/typography";

const SnippetTextCSS = css`
  ${Body1CSS}
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.5px;
`;

const Root = styled.div`
  display: grid;
  grid-template-columns: max-content 3fr max-content;
  min-height: 80px;
  border-radius: 5px;
  max-width: 916px;
  align-items: center;
  padding-left: 10px;
  background-color: ${(props) => props.backgroundColor || "unset"};
  width: 100%;
`;
const Content = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 3px;
  align-items: center;
  padding: 10px;
`;
const Title = styled.span`
  ${SnippetTextCSS}
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.textColor};
`;
const Text = styled.span`
  ${SnippetTextCSS}
  line-height: 21px;
  color: ${(props) => props.textColor};
`;
const Label = styled.div`
  ${SnippetTextCSS}
  font-size: 14px;
  align-self: start;
  font-weight: bold;
  color: ${(props) => props.theme.colors.cruise};
  padding-top: 5px;
  padding-right: 10px;
`;
const SnippetImage = styled(GatsbyImage)`
  grid-area: 1/1;
  margin-left: 10px;
  margin-right: 10px;
  align-self: center;
  justify-content: center;
  margin-top: 0px !important;
`;

function Snippet({ className, type, title, children, theme }) {
  const imageData = useStaticQuery(graphql`
    query {
      lightbulbIcon: file(relativePath: { eq: "lightbulb-icon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, height: 60)
        }
      }
      faqIcon: file(relativePath: { eq: "faq-icon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, height: 60)
        }
      }
      gearsIcon: file(relativePath: { eq: "gears-icon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, height: 60)
        }
      }
    }
  `);

  let label = "FAQ";
  let backgroundColor = theme.colors.ice;
  let imageName = "faqIcon";
  let textColor = theme.colors.deep;
  let altText = "FAQ Icon";

  if (type === "definition") {
    backgroundColor = theme.colors.deep;
    label = "DEFINITION";
    textColor = theme.colors.day;
    imageName = "gearsIcon";
    altText = "Gears Icon";
  }

  if (type === "tip") {
    label = "TIP";
    imageName = "lightbulbIcon";
    altText = "Lightbulb Icon";
  }

  return (
    <Root className={className} type={type} backgroundColor={backgroundColor}>
      <SnippetImage
        style={{ marginTop: 0 }}
        image={getImage(imageData[imageName])}
        alt={altText}
      />
      <Content>
        <Title textColor={textColor}>{title}</Title>
        <Text textColor={textColor}> {children}</Text>
      </Content>
      <Label>{label}</Label>
    </Root>
  );
}

export default styled(withTheme(Snippet))``;
