import styled from "styled-components";
import Card from "../../card/card";

const toPosition = (position) => {
  if (position === "left" || position === "top") {
    return "start";
  }

  if (position === "right" || position === "bottom") {
    return "end";
  }

  return position;
};

const Grid = styled.div`
  display: grid;
  gap: ${({ $rowGap }) => $rowGap}px ${({ $columnGap }) => $columnGap}px;
  justify-items: ${({ $horizontalAlign }) => toPosition($horizontalAlign)};
  align-items: ${({ $verticalAlign }) => toPosition($verticalAlign)};
  justify-content: center;
  align-content: center;
  ${Card} {
    ${({ $textAlign }) => `text-align: ${$textAlign}`}
  }

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    grid-template-columns: ${({ $tablet }) => {
      return $tablet
        .split(" ")
        .map((item) => item + "fr")
        .join(" ");
    }};
  }

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    grid-template-columns: ${({ $desktop }) => {
      return $desktop
        .split(" ")
        .map((item) => item + "fr")
        .join(" ");
    }};
  }
`;

Grid.defaultProps = {
  $rowGap: 10,
  $columnGap: 10,
  $verticalAlign: "center",
  $horizontalAlign: "center",
  $textAlign: "center",
  $desktop: "1",
  $tablet: "1",
};

export default Grid;
