import * as React from "react";
import { userTrackingContext } from "./user-tracking-context";
import { initUser, onLinkClick, onMessageHandler } from "./user-tracking-utils";

export function UserTrackingProvider(props) {
  const { children, location } = props;

  const { Provider } = userTrackingContext;

  const [isReady, setIsReady] = React.useState(false);

  const onInit = async () => {
    await initUser(location);
    setIsReady(true);
  };

  React.useEffect(() => {
    onInit();
  }, [location]);

  React.useEffect(() => {
    if (isReady) {
      document.body.addEventListener("click", onLinkClick);
      addEventListener("message", onMessageHandler);

      window.opener?.postMessage(
        {
          type: "vorne-marketing-user-request",
        },
        "*",
      );

      return () => {
        document.body.removeEventListener("click", onLinkClick);
        removeEventListener("message", onMessageHandler);
      };
    }
  }, [isReady]);

  return <Provider value={{ isReady }}>{children}</Provider>;
}
