import React from "react";
import styled, { css } from "styled-components";

const DefaultFontStack = css`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
`;

const DecorativeFontStack = css`
  font-family: "Gill Sans", "Trebuchet MS", Calibri, Arial, sans-serif;
`;

const Heading1CSS = css`
  ${DefaultFontStack}
  text-transform: uppercase;
  font-size: 27px;
  color: ${(props) => props.theme.colors.awesome};
  letter-spacing: 0.25px;
  font-weight: 400;
`;

const Heading1 = styled.h2`
  ${Heading1CSS}
  margin: 0px;
  padding: 0px;
`;

const Heading2CSS = css`
  ${DefaultFontStack}
  font-size: 22px;
  color: ${(props) => props.theme.colors.highEmphasis};
  font-weight: 400;
  letter-spacing: 0.25px;
`;

const Heading3CSS = css`
  ${DefaultFontStack}
  font-weight: 700;
  font-size: 17px;
  color: ${(props) => props.theme.colors.highEmphasis};
  letter-spacing: 0.75px;
`;

const Heading3 = styled.h4`
  ${Heading3CSS}
  padding: 0px;
  margin: 0px;
`;

const Body1CSS = css`
  ${DefaultFontStack}
  font-size: 17px;
  color: ${(props) => props.theme.colors.highEmphasis};
  line-height: 30px;
  letter-spacing: 0.5px;
`;

const Body1 = styled.p`
  ${Body1CSS}
  margin: 0px;
  padding: 0px;
`;

const Heading2Text = styled.h3`
  ${Heading2CSS}
  display: block;
  margin: 0px;
  padding: 0px;
`;

const Heading2SubHead = styled.span`
  ${Body1CSS}
  color: ${(props) => props.theme.colors.manatee};
`;

function Heading2Wrapper(props) {
  return (
    <React.Fragment>
      <Heading2Text className={props.className}>{props.children}</Heading2Text>
      {props.subhead ? (
        <Heading2SubHead>{props.subhead}</Heading2SubHead>
      ) : null}
    </React.Fragment>
  );
}

export const Heading2 = styled(Heading2Wrapper)``;

const ImageCaptionText = styled.p`
  ${DefaultFontStack}
  font-size: 15px;
  color: ${(props) => props.theme.colors.mediumEmphasis};
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0px;
  padding: 0px;
`;

export {
  DefaultFontStack,
  DecorativeFontStack,
  Heading1,
  Heading1CSS,
  Heading2CSS,
  Heading3,
  Heading3CSS,
  Heading2SubHead,
  Body1,
  Body1CSS,
  ImageCaptionText,
};
