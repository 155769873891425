import React, { cloneElement, Children } from "react";
import styled, { css } from "styled-components";
import { Body1CSS } from "../../typography/typography";

const LIST_TYPES = {
  BULLET: "bullet",
  NUMBER: "number",
};

const ListSharedCSS = css`
  margin: 0px;
  line-height: 30px;
  list-style: none;
  padding-left: 14px;
  padding-right: 14px;

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const BulletList = styled.ul`
  ${ListSharedCSS}
`;

const NumberList = styled.ol`
  ${ListSharedCSS}
`;

const BulletListItem = styled.li`
  &::before {
    position: relative;
    background-color: ${(props) => props.theme.colors.awesome};
    display: inline-block;
    height: 7px;
    width: 7px;
    content: " ";
    top: -3px;
    margin-left: -16px;
    margin-right: 10px;
    border-radius: 2px;
  }
  ${Body1CSS}
  font-size: ${(props) => (props.version === "v2" ? "18px" : "17px")};
`;

const NumberListItem = styled.li`
  ${Body1CSS}
  counter-increment: item;
  padding: 0px;
  margin-left: 9px;
  &::before {
    content: counter(item) ".";
    color: ${(props) => props.theme.colors.awesome};
    font-weight: bold;
    display: inline-block;
    width: 12px;
    margin-left: calc(-1em - 7px);
    margin-right: 10px;
  }
`;

const ListItem = ({ type, children, style, version }) => {
  if (type === LIST_TYPES.BULLET) {
    return (
      <BulletListItem version={version} style={{ color: style?.color }}>
        {children}
      </BulletListItem>
    );
  } else if (type === LIST_TYPES.NUMBER) {
    return <NumberListItem>{children}</NumberListItem>;
  } else {
    throw "Invalid list item type";
  }
};

const List = ({ type, className, style, children }) => {
  if (type === LIST_TYPES.BULLET) {
    return (
      <BulletList className={className} style={style}>
        {Children.map(children, (c) => cloneElement(c, { type }))}
      </BulletList>
    );
  } else if (type === LIST_TYPES.NUMBER) {
    return (
      <NumberList className={className} style={style}>
        {Children.map(children, (c) => cloneElement(c, { type }))}
      </NumberList>
    );
  } else {
    throw "List type is invalid";
  }
};

export default styled(List)``;

export { ListItem };
