import React, { Children } from "react";
import styled from "styled-components";
import Link from "../link/link";
import { Body1CSS } from "../typography/typography";

const CardTitle = styled.span`
  ${Body1CSS}
  color: ${({ theme }) => theme.colors.highEmphasis};
  font-weight: 600;
  font-size: 18px;
`;

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.cloud};
  border-radius: 10px;
  padding: 10px;
  text-decoration: none;
  cursor: ${({ $hasURL }) => ($hasURL ? "pointer" : "unset")};
  &:hover {
    text-decoration: none;
    background-color: ${({ theme, $hasURL }) =>
      $hasURL ? theme.colors.silver : theme.colors.cloud};
  }
  height: 100%;
  box-sizing: border-box;

  span {
    margin-bottom: 20px;
  }
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("tablet")} {
    margin-left: ${({ $indent }) => ($indent ? 7.5 : 0)}%;
    margin-right: ${({ $indent }) => ($indent ? 7.5 : 0)}%;
    width: ${({ $indent }) => ($indent ? 100 - 7.5 * 2 : 100)}%;
  }
`;

const Card = ({ className, children, url }) => {
  if (!!url) {
    return (
      <Container
        className={className}
        url={url}
        $hasURL={!!url}
        $indent={Children.count(children) === 1}
        rel={(url ? url[0] : "") === "/" ? null : "opener"}
        target={(url ? url[0] : "") === "/" ? null : "_blank"}
      >
        {children}
      </Container>
    );
  }

  return (
    <Container
      as="div"
      className={className}
      url={url}
      $hasURL={!!url}
      $indent={Children.count(children) === 1}
      rel={(url ? url[0] : "") === "/" ? null : "opener"}
      target={(url ? url[0] : "") === "/" ? null : "_blank"}
    >
      {children}
    </Container>
  );
};

export { CardTitle };

export default styled(Card)``;
