import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Padding from "../layout/padding";

const Divider = styled.div({
  backgroundColor: "rgba(0, 0, 0, 0.12)",
  height: 1,
  width: "100%",
});

const Root = styled.div``;

const Spec = ({ className, children }) => {
  return (
    <Root className={className}>
      <Divider />
      {React.Children.map(children, (item) => (
        <React.Fragment>
          <Padding top={30} bottom={30}>
            {item}
          </Padding>
          <Divider />
        </React.Fragment>
      ))}
    </Root>
  );
};

Spec.propTypes = {
  children: PropTypes.node,
};

export default styled(Spec)``;
